<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :disabled="loading"
    :loading="loading"
    :items="items"
    item-text="description"
    item-value="id"
    @change="select"
    return-object
  >
  </v-autocomplete>
</template>

<script>
import { formatDatespan } from "common/utils/date.js";

export default {
  name: "SchoolYearInput",
  props: {
    value: null,
    range: null, //  array
  },
  data() {
    return {
      loading: false,
      items: [],
      schoolYear: {},
    };
  },
  watch: {},
  methods: {
    formatDatespan,
    select(el) {
      this.$emit("input", el);
    },
  },
  async mounted() {
    this.loading = true;

    if (!this.range) {
      this.items = await this.apiList({
        resource: "common/schoolyear",
      });
    } else {
      if (Array.isArray(this.range) && this.range.length == 2) {
        this.items = await this.apiList({
          resource: "common/schoolyear",
          query: `offsetpast=${this.range[0]}&offsetfuture=${this.range[1]}`,
        });
      }
    }

    this.loading = false;
  },
};
</script>
